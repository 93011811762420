import { useRef, useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import ReactPlayer from "react-player/lazy";
import { Play } from "@phosphor-icons/react";
import useWindowSize from "@/hooks/useWindowSize";
import { fitIn } from "@/utils/imageHandler";

const VideoCard = ({ data, videoHeight, className }) => {
  const [play, setPlay] = useState(false);
  const windowSize = useWindowSize();

  function playVideo() {
    if (windowSize.width > 1024) {
      setPlay(true);
    }
  }
  function stopVideo() {
    setPlay(false);
  }
  return (
    <Link legacyBehavior href={`/videos/${data.slug}`} passHref>
      <a
        onMouseEnter={() => playVideo()}
        onMouseLeave={() => stopVideo()}
        className={`block relative aspect-video transition-transform origin-center duration-300 lg:hover:z-10 lg:hover:scale-[110%] ${videoHeight}`}
      >
        {play ? (
          <ReactPlayer
            url={data.video_url}
            width={"100%"}
            height={"100%"}
            playing={play}
            muted
          />
        ) : (
          <>
            <Image
              src={fitIn(data.thumbnail_url, 600)}
              objectFit='cover'
              layout='fill'
              alt='Image'
            />
            <Play
              weight='fill'
              size={48}
              className='absolute text-white opacity-70 top-[40%] left-1/2 -translate-x-1/2 -translate-y-[40%]'
            />
          </>
        )}
        <span className='absolute inset-0 bg-gradient-to-b from-[#23212E]/0 to-[#23212E]'></span>

        <span className='absolute bottom-0 left-0 w-full p-2.5 font-medium leading-snug text-white lg:p-4 font-display'>
          <span className='inline-block mb-1 text-xs font-bold text-transparent md:mb-1.5 bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
            {data.content_type.name}
          </span>
          <span
            className={`block text-base leading-tight lg:leading-tight lg:text-lg ${className}`}
          >
            {data.title}
          </span>
        </span>
      </a>
    </Link>
  );
};

export default VideoCard;
